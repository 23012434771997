import "./TemplateListing.css";
import React, { Component } from "react";
import { Card, Table, Spin, Button } from "antd";
import AuthService from "../../services/api";
import AssignCourses from "../../components/AssignCourse/AssignCourse";
import AssignSignee from "../../components/AssignSignee/AssignSignee";
import Details from "../../components/DetailsOfCourseAndSignee/Details";
import AddTemplate from "../../components/AddTemplate/AddTemplate";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";

class TemplateListing extends Component {
  constructor(props) {
    super(props);
    this.course = React.createRef();
    this.signee = React.createRef();
    this.details = React.createRef();
    this.addTemplate = React.createRef();
    //state variable to store api result
    this.state = {
      data: [],
      pagination: {
        current: 1,
        pagesize: 5,
      },
    };
  }
  //api call
  componentDidMount() {
    this.fetchTemplateListing();

    this.autoUpdateDisposer = autorun(() => {
      if (this.props.dataStore.settings.fetchUserManagementData) {
        this.fetchTemplateListing();
      }
    });
  }

  componentWillUnmount() {
    this.autoUpdateDisposer();
  }

  fetchTemplateListing = () => {
    if (document.getElementById("template-spin") !== null)
      document.getElementById("template-spin").style.display = "block";
    AuthService.requestMethod("/listCertificates")
      .then((response) => {
        if (document.getElementById("template-spin") !== null)
          document.getElementById("template-spin").style.display = "none";
        if (response && response.data)
          this.setState({
            data: response.data.certificates,
          });
      })
      .catch((error) => {
        if (document.getElementById("template-spin") !== null)
          document.getElementById("template-spin").style.display = "none";
        console.log(error);
      });

    this.props.dataStore.settings.fetchUserManagementData = false;
  };

  handleStandardTableChanges = (paginationProps) => {
    let { pagination } = this.state;
    pagination.pagesize = paginationProps.pagesize;
    pagination.current = paginationProps.current;
  };

  //on view click open the file in seperate tab [to view certificates]
  handleViewClick = (index) => {
    window.open(index.sample_file, "_blank");
  };

  handleTemplateClick = () => {
    this.addTemplate.current.setModalVisibility(true, false);
  };

  render() {
    const columns = [
      {
        title: "Sl.No",
        width: 80,
        dataIndex: "id",
        key: "id",
        render: (index, text, record) => {
          return <>{(pagination.current - 1) * 10 + record + 1}</>;
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 300,
      },
      {
        title: "View",
        dataIndex: "",
        key: "",
        width: 200,
        render: (index) => {
          return (
            <>
              {index.id ? (
                <span
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#57c059",
                    padding: "5px 40px",
                    borderRadius: 15,
                    color: "#fff",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleViewClick(index);
                  }}
                >
                  {index.id ? <span>View</span> : null}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        title: "Course",
        dataIndex: "",
        key: "",
        width: 200,
        render: (index) => {
          return (
            <>
              {index.id ? (
                <span
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#5a4bb3",
                    padding: "5px 15px 5px 15px",
                    borderRadius: 15,
                    color: "#fff",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.course.current.setModalVisibility(
                      true,
                      false,
                      index.id
                    );
                  }}
                >
                  {index.id ? <span>Assign Course</span> : null}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        title: "Signee",
        dataIndex: "",
        key: "",
        width: 200,
        render: (index) => {
          return (
            <>
              {index.id ? (
                <span
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#5a4bb3",
                    padding: "5px 15px 5px 15px",
                    borderRadius: 15,
                    color: "#fff",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.signee.current.setModalVisibility(
                      true,
                      false,
                      index.id,
                      index.no_of_signees
                    );
                  }}
                >
                  {index.id ? <span>Assign Signee</span> : null}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        title: "Details",
        dataIndex: "",
        key: "",
        width: 200,
        render: (index) => {
          return (
            <>
              {index.id ? (
                <span
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#5a4bb3",
                    padding: "5px 15px 5px 15px",
                    borderRadius: 15,
                    color: "#fff",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.details.current.setModalVisibility(true, index.id,index.skip_sign);
                  }}
                >
                  {index.id ? <span>View Details</span> : null}
                </span>
              ) : null}
            </>
          );
        },
      },
    ];

    const { data, pagination } = this.state;
    const { storage } = this.props.dataStore.header;
    let roles = [];
    if (storage && storage.role.length > 0) roles = storage.role;

    return (
      <div className="template-container">
        <div
          id="template-spin"
          style={{ display: "none", position: "relative" }}
        >
          <Spin className="spin-user" size="large" />
        </div>
        <Card className="template-header">Template Listing</Card>
        <div className="template-vertical" />
        {roles.includes(1) ? (
          <div className="template-button">
            <Button
              size={"medium"}
              id="add-user-button"
              className="add-user-button"
              onClick={this.handleTemplateClick}
              style={{
                backgroundColor: "#ff8c59",
                borderRadius: 10,
                color: "#fff",
              }}
            >
              Add Template
            </Button>
          </div>
        ) : null}

        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={data}
          // scroll={{ x: "max-content", y: 263 }}
          pagination={pagination}
          pagesize={pagination.pagesize}
          onChange={this.handleStandardTableChanges}
        />
        <AssignCourses ref={this.course} />
        <AssignSignee ref={this.signee} />
        <Details ref={this.details} />
        <AddTemplate ref={this.addTemplate} />
      </div>
    );
  }
}

export default inject("dataStore")(observer(TemplateListing));
