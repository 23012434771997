import "./Login.css";
import React from "react";
import tick from "../../assets/tickmark.svg";

const ForgotPasswordSent = () => {
  return (
    <div className="forgot-password-sent-container">
      <div className="main">
        <div style={{ textAlign: "center" }}>
          <img src={tick} alt="tick" className="tick-icon" />
        </div>

        <div className="confirmation-text">
          Password reset instructions sent.
        </div>

        <div className="confirmation-subtext">
          Please check your email for a link to reset your password.
        </div>

        <div className="form-item">
          <button
            type="submit"
            className="submit-button"
            style={{ maxWidth: "max-content", cursor:"pointer" }}
          >
            <div className="link">
              <a href="/user/login">Back to home</a>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSent;
