import "./Settings.css";
import { Route, Switch, Redirect } from "react-router-dom";
import React from "react";

import UserManagement from "./Settings";
import TemplateManagement from "./TemplateListing";
import Exception from "../../components/Exception/Exception";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import UserProfile from "../../components/UserProfile/UserProfile";
import AddUser from "../../components/AddUser/AddUser";
import ConfigurationSettings from "./ConfigurationSettings";
import CompanySettings from "./CompanySettings";
import RoleManagement from "./RoleManagement";
import Signature from "./Signature";
import UserConfigurations from "./UserConfguration"
import CourseImport from "./CourseImport";
import CampusImport from "./CampusImport";
import StudentImport from "./StudentImport";

const MainSettings = () => {
  let user =
    JSON.parse(localStorage.getItem("certificate-automation-authority")) ||
    JSON.parse(sessionStorage.getItem("certificate-automation-authority"));
  return (
    <div className="main-settings-outside-continer">
      <Switch>
        <PrivateRoute
          path="/settings/user-management/:id"
          component={UserProfile}
        />

        <Route
          exact
          path="/settings/user-management"
          component={UserManagement}
        />
        <Route
          exact
          path="/settings/template-management"
          component={TemplateManagement}
        />
        <Route
          exact
          path="/settings/configuration-settings"
          component={ConfigurationSettings}
        />
         <Route
          exact
          path="/settings/user-configurations"
          component={UserConfigurations}
        />
        <Route
          exact
          path="/settings/company-settings"
          component={CompanySettings}
        />
        <Route
          exact
          path="/settings/role-management"
          component={RoleManagement}
        />
        <Route
          exact
          path="/settings/course-import"
          component={CourseImport}
        />
        <Route
          exact
          path="/settings/campus-import"
          component={CampusImport}
        />
        <Route
          exact
          path="/settings/student-import"
          component={StudentImport}
        />

        <Route exact path="/settings/upload-signature" component={Signature} />

        <PrivateRoute path="/settings/add-user" component={AddUser} />
        {user && user.settings ? (
          user.settings.map((module, index) => (
            <Route
              key='id'
              exact
              path="/settings"
              render={() => <Redirect to={module.module_url} />}
            />
          ))
        ) : (
          <Route
          key='id'
            exact
            path="/settings"
            render={() => <Redirect to="/settings/user-management" />}
          />
        )}
        <Route component={Exception} />
      </Switch>
    </div>
  );
};

export default MainSettings;
