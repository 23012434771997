import "./Settings.css";
import React, { Component } from "react";
import { Card, Spin, Radio, Upload, Button, Tag, message } from "antd";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import sign from "../../assets/contract.svg";
import { UploadOutlined } from "@ant-design/icons";
import SignaturePad from "../../components/Signature/SignaturePad";
import AuthService from "../../services/api";

class Signature extends Component {
  constructor(props) {
    super(props);
    //state initialization
    this.state = {
      radioCheckedValue: 1,
      fileList: [],
      imageFile: null,
      isSubmitBlocked: false,
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    this.getSignatureDetails();
  }

  getSignatureDetails = () => {
    if (document.getElementById("settings-spin") !== null)
      document.getElementById("settings-spin").style.display = "block";
    AuthService.requestMethod("/signatureDetails")
      .then((response) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        if (response && response.data) {
          if (response.data.success === 1) {
            this.setState({
              imageFile: response.data.signature,
            });
          }
        }
      })
      .catch((error) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        console.log(error);
      });
  };

  onChange = (e) => {
    this.setState({
      radioCheckedValue: e.target.value,
      fileList: [],
    });
    this.props.dataStore.certificates.signature = "";
  };

  //upload file changes
  handleChange = ({ fileList }) => {
    this.setState({ fileList: fileList });
    this.props.dataStore.certificates.signature = "";
  };

  submitHandler = () => {
    message.destroy();
    const { fileList, isSubmitBlocked } = this.state;
    const { signature } = this.props.dataStore.certificates;

    if (isSubmitBlocked) return;
    this.setState({
      isSubmitBlocked: true,
    });

    const formData = new FormData();
    if (fileList.length >= 1)
      formData.append("signature", fileList[0].originFileObj);
    if (signature) formData.append("draw_signature", signature);

    if (document.getElementById("settings-spin") !== null)
      document.getElementById("settings-spin").style.display = "block";

    AuthService.requestWithPost("/uploadSignature", formData)
      .then((response) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        this.setState({
          isSubmitBlocked: false,
        });
        if (response && response.data) {
          if (response.data.success === 1) {
            message.success(response.data.message);

            if (signature) {
              this.setState({
                radioCheckedValue: 1,
                imageFile: response.data.signature,
              });
              this.props.dataStore.certificates.signature = "";
            } else {
              this.setState({
                imageFile: response.data.signature,
              });
            }
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        this.setState({
          isSubmitBlocked: false,
        });
        console.log(error);
      });
  };

  clearClickHandler = () => {
    this.child.current.clear();
  };

  render() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
      textAlign: "start",
    };

    const { radioCheckedValue, fileList, imageFile } = this.state;
    const { signature } = this.props.dataStore.certificates;

    return (
      <div className="signature-container">
        <div
          id="settings-spin"
          style={{ display: "none", position: "relative" }}
        >
          <Spin className="spin-user" size="large" />
        </div>

        <Card className="settings-header">Signature Settings</Card>
        <div className="settings-vertical" />
        <div className="sign-conatiner">
          <div className="signature-sub-container">
            <img
              className="signature-icon"
              src={sign}
              alt="Signature"
              width="50"
              height="30"
            />
            <span className="signature-header">Add Your Signature</span>

            <div className="settings-radio-container">
              <div>
                <div className="signature-header">
                  {imageFile
                    ? "Signature exsits, do you want to change?"
                    : "Please upload/draw your signature"}
                </div>

                <div>
                  {imageFile ? (
                    <div
                      style={{
                        border: "1px solid #d3d3d3",
                        marginBottom: 20,
                        marginTop: 20,
                        minHeight: 150,
                        position: "relative",
                      }}
                    >
                      <img src={imageFile} className="image-uploaded" />
                    </div>
                  ) : null}
                </div>

                <Radio.Group onChange={this.onChange} value={radioCheckedValue}>
                  <Radio style={radioStyle} value={1}>
                    Upload Your Signature
                  </Radio>
                  {radioCheckedValue === 1 ? (
                    <div
                      style={{ marginTop: 10, textAlign: "start", width: 500 }}
                    >
                      <Tag>
                        Dimensions: Maximum Width=200,Maximum Height=100
                      </Tag>
                      <div
                        className="upload-signature"
                        style={{ marginBottom: 10 }}
                      >
                        <Upload
                          listType="picture"
                          onChange={this.handleChange}
                          beforeUpload={() => false}
                          // defaultFileList={[...fileList]}
                        >
                          {fileList && fileList[0] ? (
                            <Button disabled icon={<UploadOutlined />}>
                              Upload
                            </Button>
                          ) : (
                            <Button
                              className="upload"
                              icon={<UploadOutlined />}
                            >
                              Upload
                            </Button>
                          )}
                        </Upload>
                      </div>
                    </div>
                  ) : null}
                  <Radio style={radioStyle} value={2}>
                    Draw Your Signature
                  </Radio>
                  {radioCheckedValue === 2 ? (
                    <>
                      <div style={{ textAlign: "start", margin: "10px 0px" }}>
                        <Tag style={{ fontSize: "13px" }}>
                          Note: Draw within the canvas
                        </Tag>
                      </div>

                      <SignaturePad ref={this.child} />
                    </>
                  ) : null}
                </Radio.Group>

                <div
                  style={{
                    borderTop: "1px solid #d3d3d3",
                    marginTop: 20,
                  }}
                >
                  <Button
                    className="submit-signature"
                    onClick={this.submitHandler}
                    disabled={fileList.length > 0 || signature ? false : true}
                  >
                    Submit
                  </Button>

                  {radioCheckedValue === 2 ? (
                    <Button
                      className="clear"
                      onClick={this.clearClickHandler}
                      disabled={signature ? false : true}
                    >
                      Clear
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(inject("dataStore")(observer(Signature)));
