import React, { useState } from "react";
import { Spin, Form, Input, Button, message } from "antd";
import logo from "../../assets/e-cert.svg";
import AuthService from "../../services/api";
import { Redirect } from "react-router-dom";

const ForgotPassword = () => {
  const [isRedirect, setIsRedirect] = useState(false);

  // function called on submitting
  const onHandleSubmit = (values) => {
    //initialize spinner
    if (document.getElementById("login-spin") !== null)
      document.getElementById("login-spin").style.display = "block";

    //params to be passed as form data in api
    let params = {};
    params.email = Object.values(values)[0];

    AuthService.requestWithPost("/password/email", params)
      .then((response) => {
        message.destroy();
        //hide progress bar
        if (document.getElementById("login-spin") !== null)
          document.getElementById("login-spin").style.display = "none";
        if (response && response.data && response.data.success === 0) {
          message.error(response.data.message);
        } else {
          setIsRedirect(true);
        }
      })
      .catch((error) => {
        //hide progress bar
        if (document.getElementById("login-spin") !== null)
          document.getElementById("login-spin").style.display = "none";
      });
  };

  if (isRedirect) return <Redirect to="/user/forgot-password-sent" />;

  return (
    <div className="container" id="container">
      <div id="login-spin" style={{ display: "none", position: "relative" }}>
        <Spin className="spin-user" size="large" />
      </div>
      <div className="form-container sign-in-container">
        <div className="form-container-panel">
          <Form
            name="basic"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onHandleSubmit}
          >
            <span className="forgot-account-header">Forgot your password?</span>
            <span className="forgot-account-sub-header">
              Enter your email to sent password reset instructions.
            </span>

            <Form.Item
              name="forgot-email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
            >
              <div className="input-credentials-container-password">
                <Input placeholder="Email" className="input-credentials" />
              </div>
            </Form.Item>

            <Form.Item noStyle>
              <Button shape="round" type="primary" htmlType="submit">
                Send password reset email
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-right">
            <img className="logo" src={logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
