import "./UserProfile.css";
import React, { Component } from "react";
import { Card, Input, Switch, Button, message, Spin } from "antd";
import AuthService from "../../services/api";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

let id = "";
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isSubmitBlocked: false,
    };
  }

  componentDidMount() {
    //get the selected id passed as history param from user management page.
    //suppose page is refreshed , then history param will be empty, in that case
    //take the id from local storage.
    if (this.props.location.id) {
      id = this.props.location.id;
    } else if (
      localStorage.getItem("certificate-user-edit-id") !== null ||
      sessionStorage.getItem("certificate-user-edit-id") !== null
    ) {
      id =
        localStorage.getItem("certificate-user-edit-id") ||
        sessionStorage.getItem("certificate-user-edit-id");
    }

    //api call, with id as path param
    this.fetchUserProfileData(id);
  }

  componentWillUnmount() {
    // the selected id is stored in local storage, will navigated back to the user management listing page
    //the local storage need to be cleared.
    if (
      localStorage.getItem("certificate-user-edit-id") ||
      sessionStorage.getItem("certificate-user-edit-id")
    )
      localStorage.removeItem("certificate-user-edit-id") ||
        sessionStorage.removeItem("certificate-user-edit-id");
    this.props.dataStore.settings.userDetails = {};
    this.props.dataStore.settings.statusValue = null;
  }

  //when cancel button is clicked, redirect back to settings listing page
  cancelHandleClickListener = () => {
    this.props.history.push({
      pathname: "/settings/user-management",
    });
  };

  // get api - to get all the user details of the corresponding user
  fetchUserProfileData = (id) => {
    const { isSubmitBlocked } = this.state;
    if (isSubmitBlocked) return;
    this.setState({
      isSubmitBlocked: true,
    });
    if (document.getElementById("user-profile-spin") !== null)
      document.getElementById("user-profile-spin").style.display = "block";
    AuthService.requestMethod(`/user/${id}/view`)
      .then((response) => {
        this.setState({
          isSubmitBlocked: false,
        });

        if (document.getElementById("user-profile-spin") !== null)
          document.getElementById("user-profile-spin").style.display = "none";
        if (response && response.data) {
          document.getElementById("update-button").style.visibility = "visible";
          document.getElementById("back-button").style.visibility = "visible";
          //storing the response in a data store object
          //before storing delete the unwanted keys
          delete response.data["user_details"].id;
          delete response.data["user_details"].email_verified_at;
          delete response.data["user_details"].created_at;
          delete response.data["user_details"].updated_at;
          delete response.data["user_details"].deleted_at;
          this.props.dataStore.settings.userDetails =
            response.data["user_details"];

          //store the status value in a seperate variable, to avoid object looping
          this.props.dataStore.settings.statusValue =
            response.data["user_details"].status;
        }
      })
      .then((error) => {
        if (document.getElementById("user-profile-spin") !== null)
          document.getElementById("user-profile-spin").style.display = "none";
        console.log(error);
        this.setState({
          isSubmitBlocked: false,
        });
      });
  };

  toggleOnChange = (checked) => {
    //based on toggling the value is updated
    this.props.dataStore.settings.statusValue = checked;
  };

  handleInputChange = (value, passedKeys) => {
    //the edited values will be stored in mobx variable accordingly.
    var userDetails = this.props.dataStore.settings.userDetails;
    Object.entries(userDetails).forEach((key, val) => {
      if (passedKeys === key[0]) userDetails[passedKeys] = value;
    });
  };

  //api call on update click
  onUpdateClick = () => {
    const { isSubmitBlocked } = this.state;
    if (isSubmitBlocked) return;
    this.setState({
      isSubmitBlocked: true,
    });
    if (document.getElementById("user-profile-spin") !== null)
      document.getElementById("user-profile-spin").style.display = "block";
    const { userDetails, statusValue } = this.props.dataStore.settings;
    let params = {};
    params._method = "PUT";
    params.first_name = userDetails["first_name"];
    params.last_name = userDetails["last_name"];
    params.mobile_number = userDetails["mobile_number"];
    params.status = statusValue;

    AuthService.requestWithPost(`/user/${id}/update`, params)
      .then((response) => {
        if (document.getElementById("user-profile-spin") !== null)
          document.getElementById("user-profile-spin").style.display = "none";
        //display the success message and go back to the listing page.
        message.success(response.data.message);
        this.props.history.goBack();
        this.setState({
          isSubmitBlocked: false,
        });
      })
      .catch((error) => {
        if (document.getElementById("user-profile-spin") !== null)
          document.getElementById("user-profile-spin").style.display = "none";
        console.log(error);
        this.setState({
          isSubmitBlocked: false,
        });
      });
  };

  render() {
    //the data that needs to be displayed is stored in an object as key value pair.
    //key -> same as the key from api
    //value -> the data that need to be displayed
    const displayObjects = {
      user_id: "User Id",
      first_name: "First Name",
      last_name: "Last Name",
      mobile_number: "Mobile Number",
      email: "Email",
    };

    //this condition will check for all falsy values
    if (this.props.dataStore.settings.userDetails) {
      var userDetails = this.props.dataStore.settings.userDetails;
      var statusValue = this.props.dataStore.settings.statusValue;
    }

    return (
      <div className="user-profile-container">
        <div
          id="user-profile-spin"
          style={{ display: "none", position: "relative" }}
        >
          <Spin className="spin-user" size="large" />
        </div>
        <Card className="user-profile-header">Update User</Card>
        <div className="user-profile-divider" />

        <div className="certificate-profile-wrapper">
          {Object.keys(userDetails).map((key, index) => {
            return (
              <>
                {key === "user_id" || key === "email" ? (
                  <>
                    <div>{displayObjects[key]}</div>
                    {/* {displayObjects[key] ? <div>{userDetails[key]}</div> : null} */}
                    <div>{userDetails[key]}</div>
                  </>
                ) : (
                  <>
                    <div>{displayObjects[key]}</div>
                    {displayObjects[key] ? (
                      <div>
                        <Input
                          defaultValue={userDetails[key]}
                          onChange={(e) => {
                            this.handleInputChange(e.target.value, key);
                          }}
                        ></Input>
                      </div>
                    ) : null}
                  </>
                )}
              </>
            );
          })}
        </div>

        {statusValue !== undefined && statusValue !== null ? (
          <div className="user-profile-switch">
            <div></div>
            <span className="user-profile-switch-name">
              Status
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={statusValue}
                onChange={this.toggleOnChange}
              />
            </span>
          </div>
        ) : null}

        <div className="user-profile-sub-divider" />
        <div className="user-profile-update-container">
          <Button
            size={"medium"}
            id="update-button"
            className="update-button"
            onClick={this.onUpdateClick}
          >
            Update
          </Button>
          <Button
            id="back-button"
            className="user-back-button"
            onClick={this.cancelHandleClickListener}
          >
            Back
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(inject("dataStore")(observer(UserProfile)));
