import "./Settings.css";
import React, { Component } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Card, Table, Button, Spin } from "antd";
import { autorun } from "mobx";
import AuthService from "../../services/api";
import {
  capitalizingFirstLetter,
  changeStatusFormat,
} from "../../utils/general";
import AddRole from "../../components/AddRole/AddRole";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

class RoleManagement extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();

    //state initialization
    this.state = {
      data: [],
      pagination: {
        current: 1,
        pagesize: 5,
      },
    };
  }

  componentDidMount() {
    //api call to fetch the user listing
    this.fetchlistRoles();

    this.autoUpdateDisposer = autorun(() => {
      if (this.props.dataStore.settings.fetchUserManagementData) {
        this.fetchlistRoles();
      }
    });
  }

  componentWillUnmount() {
    this.autoUpdateDisposer();
  }

  //api call : User Listing, Method : GET , headers: Authorization token
  fetchlistRoles = () => {
    if (document.getElementById("settings-spin") !== null)
      document.getElementById("settings-spin").style.display = "block";
    AuthService.requestMethod("/listRoles")
      .then((response) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        //if the response has the user lists, then only show in the table
        if (response && response.data && response.data.roles) {
          this.setState({
            data: response.data.roles,
          });
        }
      })
      .catch((error) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        console.log(error);
      });
    this.props.dataStore.settings.fetchUserManagementData = false;
  };

  handleStandardTableChanges = (paginationProps) => {
    let { pagination } = this.state;
    pagination.pagesize = paginationProps.pagesize;
    pagination.current = paginationProps.current;
  };

  handleAddRoleButtonClick = (from, record,id) => {
    this.child.current.setModalVisibility(true, false, from, record,id);
  };

  render() {
    const { pagination, data } = this.state;

    //columns
    const columns = [
      {
        title: "Sl.No",
        dataIndex: "id",
        key: "id",
        width: 80,
        render: (index, text, record) => {
          return <>{(pagination.current - 1) * 10 + record + 1}</>;
        },
      },
      {
        title: "Roles",
        dataIndex: "name",
        key: "name",
        width: 200,
        defaultSortOrder: "ascend",
        render: (name) => capitalizingFirstLetter(name),
        sorter: (a, b) => {
          a = a.name || "";
          b = b.name || "";
          return a.localeCompare(b);
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 200,
        defaultSortOrder: "ascend",
        render: (text, record, index) => {
          return changeStatusFormat(text);
        },
        sorter: (a, b) => a.status - b.status
      },
      {
        title: "View",
        dataIndex: "",
        key: "",
        width: 100,
        render: (index) => {
          return (
            <>
              {index.id && index.users ? (
                <span
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#57c059",
                    padding: "5px 15px 5px 15px",
                    borderRadius: 15,
                    color: "#fff",
                  }}
                  onClick={this.handleAddRoleButtonClick.bind(
                    this,
                    "details",
                    index.users,
                    index.id
                  )}
                >
                  {index.id ? <span>Details</span> : null}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "",
        key: "",
        width: 30,
        render: (text, record, index) => {
          return (
            <EditOutlined
              onClick={this.handleAddRoleButtonClick.bind(
                this,
                "edit-role",
                record
              )}
            />
          );
        },
      },
    ];
    return (
      <div className="settings-container">
        <div
          id="settings-spin"
          style={{ display: "none", position: "relative" }}
        >
          <Spin className="spin-user" size="large" />
        </div>
        <Card className="settings-header">Role Management</Card>
        <div className="settings-vertical" />
        <div className="settings-sub-header">
          <Button
            size={"medium"}
            id="add-user-button"
            className="add-user-button"
            onClick={this.handleAddRoleButtonClick.bind(this, "add-role")}
            style={{
              backgroundColor: "#ff8c59",
              borderRadius: 10,
              color: "#fff",
            }}
          >
            Add Roles
          </Button>
        </div>

        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={data}
          pagination={pagination}
          pagesize={pagination.pagesize}
          onChange={this.handleStandardTableChanges}
        />
        <AddRole ref={this.child} />
      </div>
    );
  }
}

export default withRouter(inject("dataStore")(observer(RoleManagement)));
