import "./Settings.css";
import React, { useEffect, useState } from "react";
import { Spin, Card, Switch, message } from "antd";
import { changeStatusForApi } from "../../utils/general";
import AuthService from "../../services/api";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

const UserConfiguration = (props) => {
  //state variables for password security and email notifications
  const [passwordSecurity, setPasswordSecurity] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [isFromLocalStorage, setIsFromLocalStorage] = useState();

  //this useeffect will be called at the intial render
  // listData.data.message if this contains data then set that value for password security and
  // email notifications
  // by default password security and email notifications will be set to false.
  useEffect(() => {
    checkStorage();
    if (document.getElementById("settings-spin") !== null)
      document.getElementById("settings-spin").style.display = "block";

    const userConfiguration = async () => {
      const listData = await AuthService.requestMethod(
        "/userConfigurationIndex"
      );

      if (
        listData &&
        listData.data &&
        listData.data.message &&
        listData.data.message.length > 0
      ) {
        listData.data.message.forEach(function (element, index) {
          setPasswordSecurity(element.password_security);
          setEmailNotifications(element.email_notifications);
        });
      }

      if (document.getElementById("settings-spin") !== null)
        document.getElementById("settings-spin").style.display = "none";
    };
    userConfiguration();
  }, []);

  const checkStorage = () => {
    if (localStorage.getItem("certificate-automation-authority") !== null) {
      props.dataStore.header.storage = JSON.parse(
        localStorage.getItem("certificate-automation-authority")
      );
      setIsFromLocalStorage(true);
    } else if (
      sessionStorage.getItem("certificate-automation-authority") !== null
    ) {
      props.dataStore.header.storage = JSON.parse(
        sessionStorage.getItem("certificate-automation-authority")
      );
      setIsFromLocalStorage(false);
    }
  };

  //this api has to be called only when the toggle button is changed.
  const updateConfiguration = (key, checked) => {
    const { storage } = props.dataStore.header;

    if (key === "password_security") {
      setPasswordSecurity(checked);
    }
    if (key === "email_notifications") {
      setEmailNotifications(checked);
    }

    message.destroy();

    if (document.getElementById("settings-spin") !== null)
      document.getElementById("settings-spin").style.display = "block";

    const formData = new FormData();
    formData.append(key, changeStatusForApi(checked));

    const updateConfigurationData = async () => {
      const updatedData = await AuthService.requestWithPost(
        "/userConfigurationUpdate",
        formData
      );

      if (
        updatedData &&
        updatedData.data &&
        updatedData.data.status === "success"
      ) {
        message.success(updatedData.data.message);

        // if password security is changed then update it automatically.
        if (key === "password_security") {
          Object.keys(storage).forEach(function (key) {
            if (key === "password") {
              // console.log((storage[key] = checked));
              storage[key] = checked;
            }
          });

          if (isFromLocalStorage) {
            localStorage.setItem(
              "certificate-automation-authority",
              JSON.stringify(storage)
            );
          } else {
            sessionStorage.setItem(
              "certificate-automation-authority",
              JSON.stringify(storage)
            );
          }
        }
      }

      if (document.getElementById("settings-spin") !== null)
        document.getElementById("settings-spin").style.display = "none";
    };
    updateConfigurationData();
  };

  //object which contains data to be displayed
  const dataToBeDispalyed = {
    password_security: "Password Security",
    email_notifications: "Email Notifications",
  };

  return (
    <div className="configuration-settings-container">
      <div id="settings-spin" style={{ display: "none", position: "relative" }}>
        <Spin className="spin-user" size="large" />
      </div>
      <Card className="certificate-profile-header">
        User Configuration Settings{" "}
      </Card>
      <div className="configuration-settings-divider" />

      <div className="configuration-settings-wrapper" style={{ minHeight: 0 }}>
        <>
          {Object.keys(dataToBeDispalyed).map(function (key, index) {
            return (
              <>
                <div className="configuration-settings-header" key={index}>
                  {dataToBeDispalyed[key]}
                </div>
                <div className="configuration-settings-input">
                  {key === "password_security" ? (
                    <Switch
                      checked={passwordSecurity}
                      onChange={updateConfiguration.bind(this, key)}
                    />
                  ) : (
                    <Switch
                      checked={emailNotifications}
                      onChange={updateConfiguration.bind(this, key)}
                    />
                  )}
                </div>
              </>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default withRouter(inject("dataStore")(observer(UserConfiguration)));
