import React , {Component} from 'react'

class Help extends Component {
    render(){
        return(
            <div>
            Help 
            </div>
        )
    }
}

export default Help;