import "./Login.css";
import React, { useEffect, useState } from "react";
import { Form, Button, Input, Spin, message } from "antd";
import logo from "../../assets/e-cert.svg";
import AuthService from "../../services/api";
import { Redirect } from "react-router-dom";

const text = [];
text.push("Password must contain at least one");
text.push("lowercase letter, uppercase letter,");
text.push("digit, and special character.");

const ResetPassword = () => {
  const [token, setToken] = useState("");
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    let path = url.split("/").pop();
    if (path !== "reset") setToken(path);
  }, []);

  // function called on submitting
  const onHandleSubmit = (values) => {
    //initialize spinner
    if (document.getElementById("login-spin") !== null)
      document.getElementById("login-spin").style.display = "block";

    //params to be passed as form data in api
    let params = {};
    params.email = Object.values(values)[0];
    params.password = Object.values(values)[1];
    params.password_confirmation = Object.values(values)[2];

    if (token) params.token = token;

    AuthService.requestWithPost("/password/reset", params)
      .then((response) => {
        //hide progress bar
        if (document.getElementById("login-spin") !== null)
          document.getElementById("login-spin").style.display = "none";

        if (response && response.data && response.data.success === 0) {
          message.error(response.data.message);
        } else {
          message.success(response.data.message);
          setIsPasswordChanged(true);
        }
      })
      .catch((error) => {
        //hide progress bar
        if (document.getElementById("login-spin") !== null)
          document.getElementById("login-spin").style.display = "none";
      });
  };

  if (isPasswordChanged) return <Redirect to="/user/login" />;

  return (
    <div className="container" id="container">
      <div id="login-spin" style={{ display: "none", position: "relative" }}>
        <Spin className="spin-user" size="large" />
      </div>
      <div className="form-container sign-in-container">
        <div className="form-container-panel">
          <Form
            name="basic"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onHandleSubmit}
          >
            <span className="forgot-account-header">Reset your Password</span>
            <span className="forgot-account-sub-header">
              Set a new password for your account.
            </span>
            <Form.Item
              name="reset-email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
            >
              <div className="input-credentials-container-password">
                <Input placeholder="Email" className="input-credentials" />
              </div>
            </Form.Item>

            <Form.Item
              name="reset-password"
              rules={[
                {
                  required: true,
                  message: "Please enter Password",
                },
                { min: 5, message: "Password must be minimum 6 characters." },
                {
                  pattern: new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
                  ),
                  message: text.map((e) => <div>{e}</div>),
                },
              ]}
            >
              <div className="input-credentials-container-password">
                <Input
                  type="password"
                  placeholder="Password"
                  className="input-credentials"
                />
              </div>
            </Form.Item>

            <Form.Item
              name="confirm"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("reset-password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match"
                      )
                    );
                  },
                }),
              ]}
            >
              <div className="input-credentials-container-password">
                <Input
                  type="password"
                  placeholder="Password"
                  className="input-credentials"
                />
              </div>
            </Form.Item>

            <Form.Item noStyle>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Set new password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-right">
            <img className="logo" src={logo} />
            {/* <h3 className="overlay-title">Certificate Automation Tool</h3>
          <p className="overlay-sub-title">
            Enter your personal details and start journey with us.
          </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
