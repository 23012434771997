import "./Settings.css";
import React, { Component } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Card, Table, message, Button, Spin } from "antd";
import { autorun } from "mobx";
import AuthService from "../../services/api";
import {
  capitalizingFirstLetter,
  changeStatusFormat,
} from "../../utils/general";
import AssignRoles from "../../components/AssignRoles/AssignRoles";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import CancelPopUp from "../../components/UserProfile/CancelPopup";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.delete = React.createRef();
    //state initialization
    this.state = {
      data: [],
      assignRolesOptions: [],
      pagination: {
        current: 1,
        pagesize: 5,
      },
    };
  }

  componentDidMount() {
    //api call to fetch the user listing
    this.fetchUserListData();
    this.fetchRolesData();

    this.autoUpdateDisposer = autorun(() => {
      if (this.props.dataStore.settings.fetchUserManagementData) {
        this.fetchUserListData();
      }
    });
  }

  fetchRolesData = () => {
    //to get the initial roles to display
    AuthService.requestMethod("/listRoles")
      .then((response) => {
        //if the response has the user lists, then only show in the table
        if (response && response.data && response.data.roles.length > 0) {
          let rolesOptionsArray = [];
          response.data.roles.map((roles) => {
            rolesOptionsArray.push({
              value: roles.id,
              label: roles.name,
            });
            this.setState({
              assignRolesOptions: rolesOptionsArray,
            });
            return "";
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    this.autoUpdateDisposer();
  }

  //api call : User Listing, Method : GET , headers: Authorization token
  fetchUserListData = () => {
    if (document.getElementById("settings-spin") !== null)
      document.getElementById("settings-spin").style.display = "block";
    AuthService.requestMethod("/listUsers")
      .then((response) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        //if the response has the user lists, then only show in the table
        if (response && response.data && response.data.users) {
          this.setState({
            data: response.data.users,
          });
        }
      })
      .catch((error) => {
        if (document.getElementById("settings-spin") !== null)
          document.getElementById("settings-spin").style.display = "none";
        console.log(error);
      });

    this.props.dataStore.settings.fetchUserManagementData = false;
  };

  handleStandardTableChanges = (paginationProps) => {
    let { pagination } = this.state;
    pagination.pagesize = paginationProps.pagesize;
    pagination.current = paginationProps.current;
    // this.state.pagination = pagination;
    //api call
  };

  editUser = (record) => {
    const { isFromLocalStorage } = this.props.dataStore.header;
    var id = "id";
    this.props.history.push({
      pathname: "/settings/user-management/" + record[id],
      id: record[id],
    });

    //suppose navigated to edit page and the user refreshes the page, history will be empty
    //and no data will be displayed.
    //so need to store the selected ids in the local storage.
    if (isFromLocalStorage)
      localStorage.setItem("certificate-user-edit-id", record[id]);
    else sessionStorage.setItem("certificate-user-edit-id", record[id]);
  };

  //delete api, the response is displayed as a message.
  deleteUser = (record) => {
    this.delete.current.setModalVisibility(
      true,
      false,
      record.id
    );
  };

  // when add user is clicked, open a new page.
  handleAddUserButtonClick = () => {
    this.props.history.push({
      pathname: "/settings/add-user",
    });
  };

  render() {
    const { pagination, data } = this.state;
    const { storage } = this.props.dataStore.header;

    const renderCustomCell = (object) => {
      var roleName = [];
      {
        object.length > 0
          ? object.forEach(function (arrayItem) {
              roleName.push(arrayItem.name);
            })
          : roleName.push("NA");
      }
      return (
        <span
          style={{
            color: "#515974",
          }}
        >
          {roleName.join()}
        </span>
      );
    };

    //columns
    const columns = [
      {
        title: "Sl.No",
        dataIndex: "id",
        key: "id",
        width: 80,
        render: (index, text, record) => {
          return <>{(pagination.current - 1) * 10 + record + 1}</>;
        },
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        width: 200,
        defaultSortOrder: "ascend",
        render: (first_name) => capitalizingFirstLetter(first_name),
        sorter: (a, b) => {
          a = a.first_name || "";
          b = b.first_name || "";
          return a.localeCompare(b);
        },
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        width: 200,
        defaultSortOrder: "ascend",
        render: (last_name) => capitalizingFirstLetter(last_name),
        sorter: (a, b) => {
          a = a.last_name || "";
          b = b.last_name || "";
          return a.localeCompare(b);
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        defaultSortOrder: "ascend",
        sorter: (a, b) => {
          a = a.email || "";
          b = b.email || "";
          return a.localeCompare(b);
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: 100,
        render: (text, record, index) => {
          return changeStatusFormat(text);
        },
      },
      {
        title: "Role",
        dataIndex: "roles",
        key: "roles",
        width: 170,
        render: (object) => renderCustomCell(object, "requisitioner"),
      },
      {
        title: "Action",
        dataIndex: "id",
        width: 150,
        render: (text, record, index) => {
          return (
            <>
              <span
                style={{
                  cursor: "pointer",
                  backgroundColor: "#5a4bb3",
                  padding: "7px 10px",
                  borderRadius: 10,
                  color: "white",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.child.current.setModalVisibility(
                    true,
                    false,
                    record.id,
                    record.roles,
                    this.state.assignRolesOptions
                  );
                }}
              >
                Assign Role
              </span>
            </>
          );
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 30,
        render: (text, record, index) => {
          return <EditOutlined onClick={this.editUser.bind(this, record)} />;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 100,
        render: (text, record, index) => {
          return (storage && storage.userid && record.id !== storage.userid)  ? (
            <div>
              {/* {console.log("sss",record.id, user.userid)} */}
              <DeleteOutlined onClick={this.deleteUser.bind(this, record)} />
            </div>
          ) : null;
        },
      },
    ];
    return (
      <div className="settings-container">
        <div
          id="settings-spin"
          style={{ display: "none", position: "relative" }}
        >
          <Spin className="spin-user" size="large" />
        </div>
        <Card className="settings-header">User Management</Card>
        <div className="settings-vertical" />
        <div className="settings-sub-header">
          <Button
            size={"medium"}
            id="add-user-button"
            className="add-user-button"
            onClick={this.handleAddUserButtonClick}
            style={{ backgroundColor: "#ff8c59", borderRadius: 10, color:"#fff" }}
          >
            Add User
          </Button>
        </div>

        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          columns={columns}
          dataSource={data}
          // scroll={{ x: "max-content", y: 263 }}
          pagination={pagination}
          pagesize={pagination.pagesize}
          onChange={this.handleStandardTableChanges}
        />
        <AssignRoles ref={this.child} />
        <CancelPopUp ref={this.delete} />
      </div>
    );
  }
}

export default withRouter(inject("dataStore")(observer(Settings)));
